import React from 'react'
import { Container, Flex, Box, css, Text } from 'theme-ui'
import ContentText from '@solid-ui-components/ContentText'
import Reveal from '@solid-ui-components/Reveal'
import Divider from '@solid-ui-components/Divider'
import ContentImages from '@solid-ui-components/ContentImages'
import ContentButtons from '@solid-ui-components/ContentButtons'
import WithDefaultContent from '@solid-ui-blocks/WithDefaultContent'

const styles = {
  container: {
    margin: 'auto', 
    width: '90%',
    height: '500px',
    '@media (max-width: 657px)': {
      height: '400px',
    },
    '@media (max-width: 420px)': {
      width: '95%'
    }
  },
  contentTitle: {
    '@media (max-width: 991px)': {
      mt: 5
    }
  },
  title: {
    pt: '8rem',
    fontSize: '35px',
    color: '#2A205E',
    lineHeight: '3rem',
    fontWeight: 600,
    '@media (max-width: 560px)': {
      fontSize: 35
    },
    '@media (max-width: 420px)': {
      fontSize: 30,
    },
  },
  subtitle: {
    margin: 'auto',
    pt: '1rem',
    '@media (max-width: 560px)': {
      textAlign: 'center'
    },
  },
  icon: {
    '@media (max-width: 991px)': {
      margin: 'auto',
      textAlign: 'left'
    },
    '@media (max-width: 791px)': {
      margin: 'auto',
      width: 400,
      textAlign: 'left'
    },
  }
}

const HeroBlock01 = ({
  content: { text = [], images, buttons },
  reverse
}) => (
  <Box sx={styles.container}>
    <Flex
      sx={{
        alignItems: ['center', null, null, `center`],
        flexDirection: [
          reverse ? `column` : `column-reverse`,
          null,
          null,
          reverse ? `row-reverse` : `row`
        ]
      }}
    >
      <Box
        sx={{
          [reverse ? 'ml' : 'mr']: [null, null, null, 5],
          position: `relative`,
          textAlign: `center`,
        }}
      >
        <ContentImages
          content={{ images }}
          loading='eager'
          reverse={reverse}
          imagePosition='center'
        />
      </Box>

      <Box
        sx={{
          margin: 'auto',
          textAlign: [`center`, null, null, `center`]
        }}
      >
        <Reveal effect='fadeInDown'>
          <Text sx={styles.title}>¡Seguinos en redes!</Text>
          <ContentText sx={styles.subtitle} content={text} />
        </Reveal>
        {buttons && (
          <Reveal
            effect='fadeInRight'
            delay={1}
            css={css({ mb: [4, null, null, 0] })}
          >
            {buttons && (
              <>
                <Divider space={4} />
                <ContentButtons content={buttons} />
              </>
            )}
          </Reveal>
        )}
        <Box>

        </Box>
        {/* {form && (
          <Reveal
            effect='fadeInRight'
            delay={1}
            css={css({ mb: [4, null, null, 0], mt: 4 })}
          >
            <QuickSignupForm {...form} space={3} />
          </Reveal>
        )} */}
      </Box>
    </Flex>
  </Box>  
)


export default WithDefaultContent(HeroBlock01)
