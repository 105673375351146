import React from 'react'
import { normalizeBlockContentNodes } from '@blocks-helpers'
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'
import theme from './_theme'
import styles from './_styles'

import { Container, Box, Text } from 'theme-ui'
import Layout from '@solid-ui-layout/Layout'
import Seo from '@solid-ui-components/Seo'
import ModalWithTabs from '@solid-ui-blocks/Modal/Block01'
import Header from '@solid-ui-blocks/Educabot/Header/header'
import WhyChooseUs from '@solid-ui-blocks/Educabot/Education/Features/features'
import Contact from '@solid-ui-blocks/Educabot/Education/Contact'
import favicon from '../../../../../../site/content/assets/favicon.png'

import HeroOne from '@solid-ui-blocks/Educabot/Atornillados/HeroOne'
import HeroTwo from '@solid-ui-blocks/Educabot/Atornillados/HeroTwo'
import HeroThree from '@solid-ui-blocks/Educabot/Atornillados/HeroThree'
import Follow from '@solid-ui-blocks/Educabot/Atornillados/Follow'
import Footer from '@solid-ui-blocks/Educabot/Footer/Block01'
import Tabs from '@solid-ui-components/AulaMaker/Tabs'
import Screenshot from '@solid-ui-blocks/Educabot/AulaMaker/FeaturesWithPhoto/Block03'


const IndexPage = props => {
  const { allBlockContent } = props.data
  const content = normalizeBlockContentNodes(allBlockContent?.nodes)

  return (
    <Layout theme={theme} {...props}>
      <Seo title='EDUCABOT' />
      <Helmet>
        <link rel="icon" href={favicon} />
      </Helmet>
      {/* Modals */}
      <ModalWithTabs content={content['contact']} />
      {/* Blocks */}
      <Header content={content['header']} />
      <Box sx={styles.heroOne}>
        <HeroOne content={content['heroOneAtornillados']} reverse />
      </Box>

      <Box sx={styles.sections}>
        <HeroTwo content={content['heroTwoAtornillados']} />
      </Box>

      <Box sx={styles.heroThree}>
        <HeroThree content={content['heroThreeAtornillados']} reverse/>
      </Box>

      <Container sx={styles.pictures}>
        <Box sx={{ textAlign: `center`, maxWidth: '100%' }}>
          <Text sx={styles.title}> Fomentamos un juego sin límites </Text>
          <Text sx={styles.subTitle}>El objetivo es incentivar a que niños y niñas <strong>aprendan haciendo.</strong> Que puedan <strong>crear, construir</strong> y <strong>hasta animarse a fallar</strong>, ya que la técnica con cartones invita a hacer y deshacer con mucha facilidad.</Text>
        </Box>
        <Tabs space={3} variant='dots' position='bottom' arrows>
          <Screenshot content={content['screenshot-one-Atornillados']} />
          <Screenshot content={content['screenshot-two-Atornillados']} />
          <Screenshot content={content['screenshot-three-Atornillados']} />
          <Screenshot content={content['screenshot-four-Atornillados']} />
          <Screenshot content={content['screenshot-five-Atornillados']} />
          <Screenshot content={content['screenshot-six-Atornillados']} />
          <Screenshot content={content['screenshot-seven-Atornillados']} />
          <Screenshot content={content['screenshot-eight-Atornillados']} />
        </Tabs>
      </Container>

      <Box sx={styles.follow}>
        <Follow content={content['follow-us-atornillados']} reverse/>
      </Box>
      <Footer content={content['footer']} />
    </Layout>
  )
}

export const query = graphql`
  query juguetesAtornilladosBlockContent {
    allBlockContent(
      filter: { page: { in: ["homepage/educabot", "shared"] } }
    ) {
      nodes {
        ...BlockContent
      }
    }
  }
`
export default IndexPage
